export function convertToQuarter(dateString: string) {
  const [month, year] = dateString.split('/').map(Number);
  const quarter = Math.ceil(month / 3);
  return `Q${quarter} 20${year}`;
}

export function convertMMYYToDate(quarter: string): Date {
  const [month, year] = quarter.split('/');
  return new Date(`20${year}-${month}-01`);
}
