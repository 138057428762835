import { Fragment, useMemo, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
} from 'recharts';
import EnrollmentMetricsBar from './EnrollmentMetricsBar';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setActiveScenario,
  setHoveredScenario,
  setSelectedScenarioId,
  useScenarios,
  useSelectedScenario,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import { convertMMYYToDate, convertToQuarter } from '../../../../utils/date';
import FullscreenButton from './FullscreenButton';
import { useChartSize } from './hooks/useChartSize';
import NoChartDataMessageView from './NoChartDataMessageView';

const CustomLabel = ({
  viewBox,
  value,
  title,
}: {
  viewBox: any;
  value: string;
  title: string;
}) => {
  const { x, y } = viewBox;
  const labelY = y + 4;
  const labelX = x;

  return (
    <g>
      {/* <rect
        x={labelX}
        y={labelY - 26}
        width="60"
        height="14"
        fill="#27262B"
        rx={4}
        ry={4}
      /> */}
      <text
        x={labelX}
        y={labelY - 10}
        fill="#BFBDC9"
        textAnchor="left"
        fontSize={10}
      >
        {title} {value}
      </text>
    </g>
  );
};

const CustomTick = ({
  x,
  y,
  payload,
  showQuaters,
  index,
  visibleTicksCount,
}: any) => {
  const displayValue = showQuaters
    ? convertToQuarter(payload.value)
    : payload.value;

  return (
    <text x={x} y={y + 10} textAnchor="middle" fill="#BFBDC9" fontSize={11}>
      {displayValue}
    </text>
  );
};

type ScenarioItemProps = {
  id: string;
};

const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
  if (active && payload && payload.length) {
    const { x, y } = coordinate;
    return (
      <div>
        {/* Tooltip box */}
        <div
          style={{
            position: 'absolute',
            top: y - 60,
            left: x,
            backgroundColor: '#2f2f2f',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            textAlign: 'center',
            transform: 'translate(-50%, -100%)',
          }}
        >
          {`${payload[0].value} Patients`}
          <div
            style={{
              content: '',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              marginLeft: '-10px',
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid #2f2f2f',
            }}
          />
        </div>
        {/* Vertical line */}
        <svg
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <line
            x1={x}
            y1={y}
            x2={x}
            y2="100%"
            stroke="#7CFC00"
            strokeWidth="2"
          />
          <rect
            x={x - 5}
            y={y - 5}
            width="10"
            height="10"
            fill="#7CFC00"
            stroke="black"
            strokeWidth="1"
          />
        </svg>
      </div>
    );
  }

  return null;
};

type GraphProps = {};

function Graph(props: GraphProps) {
  const hoveredScenario = useAppSelector(
    (s) => s.enrollmentScenario.hoveredScenario,
  );
  const selectedScenarioId = useAppSelector(
    (s) => s.enrollmentScenario.selectedScenarioId,
  );
  const dispatch = useAppDispatch();
  const [isGridVisible, setIsGridVisible] = useState(false);

  const cohortSize = useAppSelector((s) => s.enrollmentScenario.cohortSize);

  const scenarios = useScenarios();
  const dates = useAppSelector((s) => s.enrollmentScenario.dates);

  const scenariosData = useMemo(() => {
    const firstScenario = scenarios[0];
    const quaters = firstScenario.data
      .map((i) => {
        const quarter = i.quarter;
        const date = convertMMYYToDate(quarter);

        if (dates.start && new Date(dates.start) > date) {
          return null;
        }

        if (dates.end && new Date(dates.end) < date) {
          return null;
        }

        return quarter;
      })
      .filter((q) => q);

    const data = quaters.map((quarter) => {
      const quarterData = scenarios.reduce((acc, scenario) => {
        const scenarioData = scenario.data.find((d) => d.quarter === quarter);
        return {
          ...acc,
          [scenario.id]: {
            ...scenarioData,
            color: scenario.color,
          },
        };
      }, {});

      return {
        quarter,
        ...quarterData,
      };
    });

    return data;
  }, [scenarios, dates]);

  const { chartContainerRef, width, height } = useChartSize();
  const [isXAxisHovered, setIsXAxisHovered] = useState(false);
  const isChartAnimationEnabled = useAppSelector(
    (s) => s.enrollmentScenario.isChartAnimationEnabled,
  );

  return (
    <div
      ref={chartContainerRef}
      className="w-full absolute top-0 left-0 right-0 bottom-0"
      style={{ height }}
      onMouseEnter={() => setIsGridVisible(true)}
      onMouseLeave={() => setIsGridVisible(false)}
    >
      <NoChartDataMessageView data={scenariosData} />
      <LineChart
        width={width}
        height={height}
        data={scenariosData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          stroke={
            isGridVisible && scenariosData.length > 0
              ? '#47464B'
              : 'transparent'
          }
          horizontal
          vertical={false}
        />
        <XAxis
          dataKey="quarter"
          tickSize={0}
          tickMargin={12}
          tick={<CustomTick showQuaters={isXAxisHovered} />}
          onMouseEnter={() => setIsXAxisHovered(true)}
          onMouseLeave={() => setIsXAxisHovered(false)}
        />
        <YAxis
          tickSize={0}
          tickMargin={12}
          axisLine={false}
          tick={{ fontSize: 10, fill: '#EEEDF1' }}
        >
          <Label
            value="Est. Patients Enrolled"
            angle={-90}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
              fontSize: 12,
              fill: '#BFBDC9',
              letterSpacing: 0.5,
            }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />

        {scenarios.map((s, i) => {
          const id = s.id;
          const isSelectedScenario = selectedScenarioId === id;

          const getProps = (type: 'mostLikely' | 'bestCase' | 'worstCase') => {
            const isHoveredScenario =
              hoveredScenario?.id === id && //
              hoveredScenario.type === type;

            const opacity = isSelectedScenario //
              ? 1
              : isHoveredScenario
                ? 0.7
                : 0.5;

            return {
              dot: false,
              cursor: 'pointer',
              type: 'monotone',
              activeDot: { r: 0 },
              strokeWidth: isHoveredScenario ? 5 : 2,
              opacity,
              dataKey: `${id}.${type}`,
              stroke: s.color[type],
              onMouseEnter: () => dispatch(setHoveredScenario({ id, type })),
              onMouseLeave: () => dispatch(setHoveredScenario(null)),
              onClick: () => {
                if (isSelectedScenario) {
                  dispatch(setActiveScenario({ id, type }));
                } else {
                  dispatch(setSelectedScenarioId(id));
                }
              },
            };
          };

          return (
            <Fragment key={id}>
              <Line
                {...getProps('mostLikely')}
                type="monotone"
                isAnimationActive={isChartAnimationEnabled}
              />
              {isSelectedScenario && (
                <Line
                  {...getProps('bestCase')}
                  type="monotone"
                  isAnimationActive={isChartAnimationEnabled}
                />
              )}
              {isSelectedScenario && (
                <Line
                  {...getProps('worstCase')}
                  type="monotone"
                  isAnimationActive={isChartAnimationEnabled}
                />
              )}
            </Fragment>
          );
        })}

        <ReferenceLine
          y={cohortSize.target}
          stroke="#FFFFFF"
          strokeDasharray={'2 2'}
          opacity={0.5}
          label={({ viewBox }) => (
            <CustomLabel
              viewBox={viewBox}
              title="Target"
              value={cohortSize.target.toString()}
            />
          )}
        />
        <ReferenceLine
          y={cohortSize.min}
          stroke="#FFFFFF"
          strokeDasharray={'2 2'}
          opacity={0.5}
          label={({ viewBox }) => (
            <CustomLabel
              viewBox={viewBox}
              title="Min"
              value={cohortSize.min.toString()}
            />
          )}
        />
      </LineChart>
    </div>
  );
}

function Header() {
  const selectedScenario = useSelectedScenario();
  const target = useAppSelector((s) => s.enrollmentScenario.cohortSize.target);

  return (
    <div className="p-2 flex flex-row items-start relative">
      <div
        className="absolute top-0 bottom-0 left-0 w-1.5"
        style={{ backgroundColor: selectedScenario?.color.mostLikely }}
      ></div>
      <div className="relative mr-5"></div>

      <div className="flex flex-col flex-grow items-start mr-5">
        <div className="flex flew-row items-center">
          <h1 className="text-xl font-medium mr-5 text-left">
            {selectedScenario?.name} Enrollment Scenario
          </h1>
          <p className="text-base text-gray-400">n={target}</p>
        </div>
        <p className="text-xs text-gray-400">CWER Study</p>
      </div>

      <EnrollmentMetricsBar />
    </div>
  );
}

export default function EnrollmentScenarioGraph() {
  const selectedScenario = useSelectedScenario();

  if (!selectedScenario) {
    return null;
  }

  return (
    <div
      className="flex flex-col relative m-4 p-4 pb-8 border border-gray-700 rounded-xl text-white w-full"
      style={{ backgroundColor: '#3B3944' }}
      onClick={() => console.log('click')}
    >
      <Header />

      <div className={'flex w-full relative mt-10 mb-6 pr-4 flex-1'}>
        <Graph />
      </div>

      <FullscreenButton />
    </div>
  );
}
