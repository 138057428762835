import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimated from './components/NeurouteAnimated';
import { RootState } from '../../app/store';
import { setDisease } from '@/features/flow_1/flow_1_slice';

import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InnerText,
  InvisibleInput,
} from './components/StyledElements';
import { useAuth } from '../auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';
import { useLoadScript, Libraries } from '@react-google-maps/api';
const libraries: Libraries = ['places'];

const LocationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow1);
  const { currentUser } = useAuth();
  const [location, setLocation] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current!,
        {
          types: ['geocode'],
        },
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry && place.geometry.location) {
          const formattedAddress = place.formatted_address || '';
          setLocation(formattedAddress);
          dispatch(setDisease(formattedAddress));
        }
      });
    }
  }, [isLoaded, dispatch]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDisease(e.target.value));
  };

  const handleNextStep = () => {
    navigate('/flow-1-wireframes/step-1');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  return (
    <ProjectLayout title="Projects">
      <div className="relative">
        <a href="/profile" className="absolute top-0 right-0 m-4">
          <AuthorAvatar author={currentUser} />
        </a>
      </div>
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <NeurouteAnimated />

        <InputLikeElement>
          <InnerText>Location:</InnerText>
          <InvisibleInput
            ref={inputRef}
            placeholder="Type here..."
            value={data.disease}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <CircleButton onClick={handleNextStep}>
            <ArrowRightIcon />
          </CircleButton>
        </InputLikeElement>
      </main>
    </ProjectLayout>
  );
};

export default LocationPage;
