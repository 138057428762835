import { Map, Marker, AdvancedMarker } from '@vis.gl/react-google-maps';
import MapViewHeader from './components/MapViewHeader';
import { Fragment, useMemo, useState } from 'react';
import {
  setSelectedSiteId,
  useSelectedSite,
  useSelectedStudyNameOrId,
} from '@/features/enrollment_scenario/enrollment_scenario_slice';
import SiteInfoMapBar from './components/SiteInfoBar';
import homeIcon from '../../../../images/home-marker-icon.png';
import greenMarkerIcon from '../../../../images/green-marker.png';
import { Circle } from './components/CircleGreyZone';
import markerPinImage from '../../../../images/marker-pin.svg';
import tipIcon from '../../../../images/tip.svg';
import { useAppDispatch } from '../../../../app/hooks';
import { StudyDetails, StudySite } from '@/api/slices/types';
import { useGetStudyByNameOrNctIdQuery } from '@/api/slices/study/StudySlice';

export default function MapView() {
  const position = { lat: 53.54992, lng: 10.00678 };
  const position2 = { lat: 53.552, lng: 10.0069 };
  const position3 = { lat: 53.547, lng: 10.0065 };

  const selectedSearchStudyNameOrId: string | null = useSelectedStudyNameOrId();
  const selectedSite = useSelectedSite();

  const studiesResponse = useGetStudyByNameOrNctIdQuery({
    name_or_nct_id: selectedSearchStudyNameOrId ?? '',
  });

  // Get the studies from the response
  const studies: StudyDetails[] | undefined = useMemo(() => {
    const response = studiesResponse.data;
    if (!response) {
      return undefined;
    }
    const studies: StudyDetails[] = response.studies;
    return studies;
  }, [studiesResponse, studiesResponse.data, studiesResponse.isLoading]);

  const [showCompetition, setShowCompetition] = useState(false);
  const dispatch = useAppDispatch();

  const imageMarker = {
    url: homeIcon,
    size: new window.google.maps.Size(40, 40),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 20),
  };

  const greenMarker = {
    url: greenMarkerIcon,
    size: new window.google.maps.Size(30, 40),
    origin: new window.google.maps.Point(0, 0),
  };

  const siteMapItems = useMemo(() => {
    if (!selectedSite) {
      return null;
    }

    return (
      <>
        <Marker position={position} icon={imageMarker} />
        <Marker position={position2} icon={greenMarker} />
        <Marker position={position3} icon={greenMarker} />
        <Circle
          radius={400}
          center={position}
          onRadiusChanged={() => null}
          onCenterChanged={() => null}
          strokeOpacity={0}
          strokeWeight={0}
          fillColor={'#423B34'}
          fillOpacity={0.05}
        />
        <AdvancedMarker
          position={position}
          onClick={() => setShowCompetition(!showCompetition)}
        >
          <div className="flex flex-col">
            <div className="h-8 px-4 py-1 bg-zinc-700 rounded-[100px] justify-center items-center gap-1 inline-flex">
              <div className="text-center text-black text-[13px] font-medium font-['Satoshi Variable'] leading-normal">
                👀
              </div>
              <div className="text-center text-white text-[13px] font-medium font-['Satoshi Variable'] leading-normal">
                3 similar studies nearby
              </div>
            </div>

            <img src={markerPinImage} className="h-10 mt-0.5 mb-2" />
            <div className="h-4" />
          </div>
        </AdvancedMarker>
      </>
    );
  }, [
    selectedSite,
    position,
    position2,
    position3,
    imageMarker,
    greenMarker,
    showCompetition,
  ]);

  const scenarioMapItems = useMemo(() => {
    if (selectedSite || !studies) {
      return null;
    }

    // Collect all site coordinates from all studies
    const sites: StudySite[] = studies.flatMap(
      (study: StudyDetails) => study.sites || [],
    );

    return (
      <>
        {sites.map((site: StudySite) => {
          if (!site || !site.coordinates || site.coordinates.length < 2) {
            return null;
          }
          const selectSite = () => dispatch(setSelectedSiteId(site.id));
          const position = {
            lat: site.coordinates[0],
            lng: site.coordinates[1],
          };
          return (
            <Fragment key={site.id}>
              <Marker
                position={position}
                icon={imageMarker}
                onClick={selectSite}
              />
              <AdvancedMarker position={position} onClick={selectSite}>
                <div className="flex flex-col justify-center items-center">
                  <div className="shadow flex-col justify-start items-center inline-flex">
                    <div className="p-4 bg-white rounded flex-col justify-start items-start gap-2 flex">
                      <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="flex-col justify-start items-start flex">
                          <div className="text-center text-zinc-700 text-xs font-bold font-['Satoshi Variable'] leading-normal">
                            {site.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={tipIcon} className="h-2 w-4" alt="Icon" />
                  <div className="h-8" />
                </div>
              </AdvancedMarker>
            </Fragment>
          );
        })}
      </>
    );
  }, [studies, imageMarker, selectedSite]);

  return (
    <div
      className="flex flex-col relative m-4 border border-gray-700 rounded-xl overflow-hidden text-white w-full h-full"
      style={{ backgroundColor: '#3B3944' }}
    >
      <div className="relative h-full w-full">
        <Map
          mapId="bdd34107f185bab2"
          defaultCenter={position}
          defaultZoom={15}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          zoomControl={false}
        >
          {siteMapItems}
          {scenarioMapItems}
        </Map>
        <MapViewHeader
          showCompetition={showCompetition}
          setShowCompetition={setShowCompetition}
        />
        {selectedSite && <SiteInfoMapBar />}
      </div>
    </div>
  );
}
