import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';
import chevronImage from '../../images/chevron_gray_icon.svg';
import clsx from 'clsx';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { E164PhoneNumber } from 'aws-sdk/clients/chime';

const ForgotPasswordPageV2: React.FC = () => {
  const [step, setStep] = useState<'enterInfo' | 'enterCode' | 'resetPassword'>(
    'enterInfo',
  );
  const [selectedTab, setSelectedTab] = useState<'phone' | 'email'>('phone');
  const [phoneNumber, setPhoneNumber] = useState<E164PhoneNumber>();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState<
    'Weak' | 'Medium' | 'Strong'
  >('Weak');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleTabClick = (tab: 'phone' | 'email') => {
    setSelectedTab(tab);
    setErrorMessage('');
    setEmail('');
    setPhoneNumber(undefined);
  };

  const handleEnterInfoNextClick = () => {
    if (selectedTab === 'phone' && !phoneNumber) {
      setErrorMessage('Please enter your phone number.');
      return;
    }
    if (selectedTab === 'email' && !email) {
      setErrorMessage('Please enter your email address.');
      return;
    }
    // Implement API call to send code here
    setErrorMessage('');
    setStep('enterCode');
  };

  const handleEnterCodeNextClick = () => {
    const codeStr = code.join('');
    if (codeStr.length < 4) {
      setErrorMessage('Please enter the 4-digit code.');
      return;
    }
    // Implement code verification here
    setErrorMessage('');
    setStep('resetPassword');
  };

  const handleResetPasswordNextClick = () => {
    if (!newPassword || !confirmPassword) {
      setErrorMessage('Please fill in all password fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }
    // Implement password reset API call here
    setErrorMessage('');
    navigate('/login'); // Redirect to login page after successful reset
  };

  const handleNextClick = () => {
    if (step === 'enterInfo') {
      handleEnterInfoNextClick();
    } else if (step === 'enterCode') {
      handleEnterCodeNextClick();
    } else if (step === 'resetPassword') {
      handleResetPasswordNextClick();
    }
  };

  const handleBackClick = () => {
    if (step === 'enterCode') {
      setStep('enterInfo');
      setErrorMessage('');
    } else if (step === 'resetPassword') {
      setStep('enterCode');
      setErrorMessage('');
    } else {
      navigate(-1);
    }
  };

  const handleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      // Move focus to next input if not the last one
      if (value && index < code.length - 1) {
        const nextInput = document.getElementById(
          `code-input-${index + 1}`,
        ) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleSendAgain = () => {
    console.log('Resend code');
  };

  const evaluatePasswordStrength = (password: string) => {
    let strength: 'Weak' | 'Medium' | 'Strong' = 'Weak';
    const regexes = [
      /.{8,}/, // Minimum 8 characters
      /[A-Za-z]/, // Contains letters
      /\d/, // Contains numbers
      /[!@#$%^&*(),.?":{}|<>]/, // Contains special characters
    ];
    const passedTests = regexes.reduce(
      (acc, regex) => (regex.test(password) ? acc + 1 : acc),
      0,
    );
    if (passedTests >= 3) strength = 'Medium';
    if (passedTests === 4) strength = 'Strong';
    setPasswordStrength(strength);
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    evaluatePasswordStrength(value);
  };

  return (
    <div className="flex h-screen w-full bg-[#1F1F23] items-center justify-center">
      <div className="w-[580px] bg-[#2c2b32] rounded-lg shadow">
        <div className="px-12 py-12 flex flex-col gap-6 relative">
          {/* Back Button */}
          <button onClick={handleBackClick} className="absolute left-12 top-14">
            <img
              src={chevronImage}
              className={clsx('w-4 h-4 transform rotate-90')}
              alt="back to login"
            />
          </button>
          {/* Title */}
          <h2 className="text-center text-[#9491a7] text-[25px] font-medium">
            Sign In
          </h2>
          {/* Heading */}
          {step === 'resetPassword' ? (
            <h1 className="text-white text-[40px] font-normal">
              Create New Password
            </h1>
          ) : (
            <h1 className="text-white text-[40px] font-normal">
              Reset your password
            </h1>
          )}
          {/* Content */}
          {step === 'enterInfo' && (
            <>
              {/* Tabs */}
              <div className="flex border-b border-[#676578] mb-6">
                <button
                  onClick={() => handleTabClick('phone')}
                  className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                    selectedTab === 'phone'
                      ? 'text-white border-b-2 border-white'
                      : 'text-[#9491a7]'
                  }`}
                >
                  Phone
                </button>
                <button
                  onClick={() => handleTabClick('email')}
                  className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                    selectedTab === 'email'
                      ? 'text-white border-b-2 border-white'
                      : 'text-[#9491a7]'
                  }`}
                >
                  Email
                </button>
              </div>
              {/* Input Fields */}
              {selectedTab === 'phone' && (
                <div className="mb-6">
                  <div className="flex items-center border-b border-[#9491a7] py-2">
                    <PhoneInput
                      onChange={setPhoneNumber}
                      value={phoneNumber}
                      placeholder="Enter your phone number"
                      className="w-full"
                      numberInputProps={{
                        className:
                          'bg-transparent text-white text-[25px] font-normal border-none focus:outline-none autofill:bg-transparent autofill:text-white',
                      }}
                      countrySelectProps={{
                        className:
                          'bg-transparent text-white border-none focus:outline-none',
                      }}
                    />
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 mt-2 text-base">
                      {errorMessage}
                    </p>
                  )}
                </div>
              )}
              {selectedTab === 'email' && (
                <div className="mb-6">
                  <div className="border-b border-[#9491a7] py-2">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email address"
                      className="w-full bg-transparent text-white placeholder-[#9491a7] text-[25px] font-normal border-none focus:outline-none"
                    />
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 mt-2 text-base">
                      {errorMessage}
                    </p>
                  )}
                </div>
              )}
              {/* Sign Up Suggestion (TODO: ADD this init)
                            <div className="text-[#9491a7] text-base">
                                Maybe: This {selectedTab === 'phone' ? 'phone number' : 'email'} does not
                                exist,{' '}
                                <a href="/sign-up" className="text-[#00c7a8] underline">
                                    Sign up
                                </a>{' '}
                                instead.
                            </div> */}
            </>
          )}
          {step === 'enterCode' && (
            <>
              {/* Message */}
              <div className="mb-6">
                <div className="text-[#9491a7] text-base">
                  Enter the code we’ve sent to your{' '}
                  {selectedTab === 'phone' ? 'phone' : 'email'}{' '}
                  <span className="font-semibold">
                    {selectedTab === 'phone' ? phoneNumber : email}
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500 mt-2 text-base">{errorMessage}</p>
                )}
              </div>
              {/* Code Input Fields */}
              <div className="flex justify-between items-center mb-6">
                {code.map((digit, index) => (
                  <input
                    key={digit}
                    id={`code-input-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(e, index)}
                    className="w-[77px] h-[77px] rounded-[15px] border border-white bg-transparent text-white text-[32px] font-medium text-center"
                  />
                ))}
              </div>
              {/* Send Again */}
              <div className="flex justify-start items-center gap-2">
                <button
                  onClick={handleSendAgain}
                  className="text-[#00c7a8] text-base font-medium"
                >
                  Send Again
                </button>
              </div>
            </>
          )}
          {step === 'resetPassword' && (
            <>
              {/* New Password */}
              <div className="flex flex-col gap-6 mb-6">
                <div>
                  <div className="flex items-center px-4">
                    <input
                      type="password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                      placeholder="New Password"
                      className="w-full bg-transparent text-white placeholder-[#9491a7] text-[25px] font-normal border-none focus:outline-none"
                    />
                  </div>
                  <div className="border-b border-[#9491a7] mt-2"></div>
                </div>
                <div>
                  <div className="flex items-center px-4">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm New Password"
                      className="w-full bg-transparent text-white placeholder-[#9491a7] text-[25px] font-normal border-none focus:outline-none"
                    />
                  </div>
                  <div className="border-b border-[#9491a7] mt-2"></div>
                </div>
                {/* Password Requirements */}
                <div className="text-white text-base">
                  Your password must have at least:
                </div>
                <ul className="text-[#9491a7] text-sm list-disc list-inside">
                  <li>8 characters (20 Max)</li>
                  <li>1 letter and 1 number</li>
                  <li>1 special character (e.g., #!@%)</li>
                </ul>
                {/* Password Strength Indicator */}
                <div className="mb-6">
                  <div className="w-full h-2 bg-[#9491a7] rounded-full mb-2 overflow-hidden">
                    <div
                      className={`h-full ${
                        passwordStrength === 'Strong'
                          ? 'bg-[#00c7a8] w-full'
                          : passwordStrength === 'Medium'
                            ? 'bg-yellow-500 w-1/2'
                            : 'bg-red-500 w-1/4'
                      }`}
                    ></div>
                  </div>
                  <p className="text-white text-sm">
                    Password Strength:{' '}
                    <span className="font-bold">
                      {passwordStrength || 'N/A'}
                    </span>
                  </p>
                </div>
                {errorMessage && (
                  <p className="text-red-500 mt-2 text-base">{errorMessage}</p>
                )}
              </div>
            </>
          )}
          {/* Next Button */}
          <div className="mt-4 flex flex-col gap-4">
            <button
              onClick={handleNextClick}
              className="w-full py-3 bg-[#00c7a8] rounded-lg flex items-center justify-center gap-2 hover:bg-[#00b89b] transition"
            >
              <span className="text-[#101117] text-[25px] font-medium leading-7">
                {step === 'resetPassword' ? 'Create Password' : 'Next'}
              </span>
              <AtomIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPageV2;
