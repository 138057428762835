import AuthLayout from '@/pages/auth/components/AuthLayout';
import { getApp } from 'firebase/app';
import { useForm } from 'react-hook-form';
import { useAuth } from './AuthContext';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { getAuth, updateProfile } from 'firebase/auth';
import logoBrandImg from '@/images/logo-brand.svg';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import Input from '@/pages/auth/components/Input';
import emailSvg from '@/images/email.svg';
import nameSvg from '@/images/emotion-smile.svg';
import ActionButton from '@/components/buttons/ActionButton';
import UploadImageToS3WithReactS3 from '@/pages/dashboard/components/users/UploadImageToS3WithReactS3';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import defaultUserImg from '@/images/user.png';

const schema = z
  .object({
    displayName: z.string().min(2, 'Display name is required'),
  })
  .required();

export function ProfileForm() {
  const { currentUser, loading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [photoURL, setPhotoURL] = useState('');

  const navigate = useNavigate();

  const handleImageUpload = (url: string) => {
    setPhotoURL(url);
  };

  const onSubmit = async (data: any) => {
    const app = getApp();
    const auth = getAuth(app);
    const currentUser = auth.currentUser;

    try {
      if (currentUser) {
        const userDataToUpdate: { displayName: string; photoURL?: string } = {
          displayName: data.displayName,
        };

        if (photoURL) {
          userDataToUpdate.photoURL = photoURL;
        }

        await updateProfile(currentUser, { ...userDataToUpdate });

        await updateDoc(doc(db, 'users', currentUser.uid), userDataToUpdate);
      } else {
        setError('displayName', {
          type: 'manual',
          message: 'No user is logged in.',
        });
      }

      navigate('/profile');
    } catch (error: any) {
      switch (error.code) {
        case 'auth/network-request-failed':
          setError('displayName', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        default:
          setError('displayName', {
            type: 'manual',
            message: 'Internal error. Try again later. profilePage',
          });
          console.error(error);
          break;
      }
    }
  };

  return (
    <>
      <div>
        <div className="flex items-center space-x-4">
          <img
            src={ArrowLeftCircleIcon}
            alt="Back"
            className="w-8 h-8 cursor-pointer hover:opacity-80 transition-opacity"
            onClick={() => navigate(-1)}
          />
          <img className="h-10 w-auto" src={logoBrandImg} alt="Neuroute Logo" />
        </div>
        <div className="text-white text-[40px] font-normal mt-6">
          Update your profile
        </div>
        <h2 className="mt-1.5 text-base leading-9 tracking-tight text-gray-100">
          Enter your display name and upload an image for your profile icon
        </h2>
      </div>

      <div className="mt-10">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            className="space-y-6"
          >
            <Input
              id="email"
              title="Email Address"
              type="email"
              placeholder={currentUser?.email}
              autoComplete="email"
              inputProps={register('email')}
              readOnly
              icon={<img src={emailSvg} alt="Email" />}
            />

            <Input
              id="displayName"
              title="Display Name"
              type="text"
              placeholder={currentUser?.displayName || 'John Doe'}
              autoComplete="name"
              inputProps={register('displayName')}
              icon={<img src={nameSvg} alt="Name" />}
            />

            <div className="flex items-center space-x-6">
              <div>
                <UploadImageToS3WithReactS3 onImageUpload={handleImageUpload} />
              </div>

              <div>
                <img
                  src={currentUser?.photoURL || defaultUserImg}
                  alt="Profile"
                  className="h-9 w-9 rounded-full object-cover"
                />
              </div>
            </div>

            <ActionButton
              title="Update your profile"
              isLoading={isSubmitting}
            />

            {errors && (
              <div className="text-red-500 text-sm">
                {errors.displayName?.message?.toString()}
              </div>
            )}

            {isSubmitted && !errors.displayName && (
              <div className="text-primary text-sm">
                Your profile is updated.
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default function ProfilePage() {
  return (
    <AuthLayout>
      <ProfileForm />
    </AuthLayout>
  );
}
