import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/pages/auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';

type HamburgerMenuProps = {};

const HamburgerMenu: React.FC<HamburgerMenuProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleNavigation = (path: string) => {
    navigate(path);
    closeMenu();
  };

  return (
    <div>
      {/* Hamburger menu icon */}
      <button className="p-2 text-5xl" onClick={toggleMenu}>
        <AuthorAvatar author={currentUser} />
      </button>

      {/* Full-screen overlay and menu */}
      {menuOpen && (
        <div className="fixed inset-0 z-30">
          {/* Dark background overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={closeMenu}
          ></div>

          {/* Slide-in menu */}
          <div className="absolute top-0 right-0 w-64 h-full bg-gray-800 text-white shadow-lg transition-transform transform duration-500 ease-in-out translate-x-0">
            <button
              onClick={closeMenu}
              className="text-2xl text-gray-400 p-4 hover:text-white transition-all duration-300"
            >
              ×
            </button>
            {/* Navigation Links */}
            <nav className="mt-10 space-y-6 px-6">
              <ul>
                {currentUser ? (
                  <>
                    <li>
                      <button
                        onClick={() => handleNavigation('/profile')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        My Profile
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleNavigation('/initiatives')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        Initiatives
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleNavigation('/subscription')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        Subscription
                      </button>
                    </li>
                    {(currentUser.role === 'super-admin' ||
                      currentUser.role === 'moderator') && (
                      <li>
                        <button
                          onClick={() =>
                            handleNavigation('/comment-management')
                          }
                          className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                        >
                          Comment Management
                        </button>
                      </li>
                    )}
                    {currentUser.role === 'super-admin' && (
                      <li>
                        <button
                          onClick={() => handleNavigation('/user-management')}
                          className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                        >
                          User Management
                        </button>
                      </li>
                    )}
                    <li>
                      <button
                        onClick={() => handleNavigation('/sign-out')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        Sign Out
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button
                        onClick={() => handleNavigation('/login')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        Sign In
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => handleNavigation('/sign-up')}
                        className="block text-lg font-semibold hover:bg-gray-700 p-2 rounded-lg transition-all duration-300 text-left w-full"
                      >
                        Sign Up
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
