import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimatedSmall from './components/NeurouteAnimatedSmall';

import { RootState } from '../../app/store';
import { setHelpHow } from '../../features/flow_3/flow_3_slice';

import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InvisibleInput,
  CornerPositioned,
} from './components/StyledElements';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';

const Step3Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow3);
  const { currentUser } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHelpHow(e.target.value));
  };

  const handleNextStep = () => {
    navigate('/flow-3-wireframes/step-4');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  return (
    <ProjectLayout title="Projects">
      <CornerPositioned>
        <NeurouteAnimatedSmall />
        <div className="flex flex-row content-between items-center gap-4">
          <HamburgerMenu />
        </div>
      </CornerPositioned>
      <main className="flex-grow flex flex-col items-center p-4 pt-10">
        <div className="max-w-lg w-full text-left">
          <div className="text-gray-500 text-4xl mb-2 pl-6">
            I want to help patients with
          </div>
          <div className="text-white text-4xl mb-8 pl-6">{data.disease}</div>
          <InputLikeElement className="mt-4">
            <InvisibleInput
              placeholder="How are you going to do it? e.g. By giving them Ozempic"
              value={data.helpHow}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              autoFocus
            />
            <CircleButton onClick={handleNextStep}>
              <ArrowRightIcon />
            </CircleButton>
          </InputLikeElement>
        </div>
      </main>
    </ProjectLayout>
  );
};

export default Step3Page;
