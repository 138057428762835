import * as Sentry from '@sentry/react';

export default function setupSentry() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Sentry.init({
    dsn: 'https://d5ae1eee43259a78c5ce63a0ab1fb66c@o4507311203090432.ingest.de.sentry.io/4507311205908560',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
