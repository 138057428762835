import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimatedSmall from './components/NeurouteAnimatedSmall';
import CarouselSelector from './components/CarouselSelector';
import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InvisibleInput,
  CornerPositioned,
} from './components/StyledElements';

import { RootState } from '../../app/store';
import {
  setStudyMethod,
  selectStudyMethodText,
  selectYearsText,
} from '@/features/flow_1/flow_1_slice';
import { useAuth } from '../auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';

const listItems: string[] = ['Sleep', 'Eat', 'Do'];

const escapeSpecialCharacters = (str: string): string => {
  return str.replace(/\\/g, '\\\\').replace(/`/g, '\\`').replace(/\//g, '\\/');
};

const Step3Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow1);
  const { currentUser } = useAuth();
  const studyMethodText = useSelector((state: RootState) =>
    selectStudyMethodText(state),
  );
  const yearsText = useSelector((state: RootState) => selectYearsText(state));

  const handleChange = (value: string) => {
    dispatch(setStudyMethod(value));
  };

  const handleNextStep = () => {
    navigate('/flow-1-wireframes/step-4');
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  useEffect(() => {
    window.removeEventListener('keydown', handleKeyDown);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const escapedDisease = escapeSpecialCharacters(data.disease);

  const helpHowTextTrimmed = data.helpHow.replace(/by /g, '');

  return (
    <ProjectLayout title="Projects">
      <div className="absolute top-0 right-0 m-4">
        <a href="/profile">
          <AuthorAvatar author={currentUser} />
        </a>
      </div>
      <div className="flex flex-row">
        <CornerPositioned>
          <NeurouteAnimatedSmall />
        </CornerPositioned>

        {/* Left Side Panel */}
        <div className="w-60 max-w-60 p-4 block absolute top-10 left-0 overflow-hidden">
          <div className="mt-24">
            <CarouselSelector
              items={listItems}
              value={data.studyMethod}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Main Content */}
        <main className="w-3/4 flex-grow flex flex-col items-center p-4 pt-10 pl-36">
          <div className="max-w-lg w-full text-left">
            <div className="text-gray-500 text-4xl mb-2 pl-6">I sleep </div>
            <div className="text-white text-4xl mb-2 pl-6">{data.disease}</div>
            <div className="text-gray-500 text-4xl mb-2 pl-6">
              <span className="text-white ">{helpHowTextTrimmed}</span>
            </div>
            <div className="text-gray-500 text-4xl mb-2 pl-6"> </div>
            <div className="text-gray-500 text-4xl mb-8 mt-16 pl-6">
              I found it difficult to fall asleep
            </div>
            <InputLikeElement className="mt-4">
              <InvisibleInput
                readOnly
                placeholder={`Search for ${escapedDisease} ${yearsText}`}
              />
              <CircleButton onClick={handleNextStep}>
                <ArrowRightIcon />
              </CircleButton>
            </InputLikeElement>
          </div>
        </main>
      </div>
    </ProjectLayout>
  );
};

export default Step3Page;
