import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimated from './components/NeurouteAnimated';
import { RootState } from '../../app/store';
import { setDisease } from '@/features/flow_1/flow_1_slice';

import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InnerText,
  InvisibleInput,
} from './components/StyledElements';
import { useAuth } from '../auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';

const Step1Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow1);
  const { currentUser } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDisease(e.target.value));
  };

  const handleNextStep = () => {
    navigate('/flow-1-wireframes/step-2');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  return (
    <ProjectLayout title="Projects">
      <div className="relative">
        <a href="/profile" className="absolute top-0 right-0 m-4">
          <AuthorAvatar author={currentUser} />
        </a>
      </div>
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <NeurouteAnimated />

        <InputLikeElement>
          <InnerText>
            Tell me about your health. Let's start with sleep. I sleep
          </InnerText>
          <InvisibleInput
            placeholder="Type here..."
            value={data.disease}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <CircleButton onClick={handleNextStep}>
            <ArrowRightIcon />
          </CircleButton>
        </InputLikeElement>
      </main>
    </ProjectLayout>
  );
};

export default Step1Page;
