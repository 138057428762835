import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import photo from '@/images/user.png';
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || 'default-bucket-name';
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();

interface UploadImageToS3Props {
  onImageUpload: (url: string) => void;
}

const UploadImageToS3WithReactS3: React.FC<UploadImageToS3Props> = ({
  onImageUpload,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    const handleUpload = async (file: File | null) => {
      if (!file) {
        console.error('No file selected');
        return;
      }
      const bucketName = process.env.REACT_APP_S3_BUCKET;
      if (!bucketName) {
        console.error('S3 Bucket name is not defined in environment variables');
        return;
      }

      const params = {
        Bucket: bucketName,
        Key: file.name, // file name to be saved in S3
        Body: file,
        // ACL: 'public-read', // so that the file is publicly accessible
        ContentType: file.type,
      };

      try {
        s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
          if (err) {
            console.error('LINE 45 Upload error:', err);
            return;
          }
          console.log('LINE 49 File URL:', data?.Location); // URL of the uploaded image
          setImageUrl(data?.Location); // Save the URL to state
          onImageUpload(data?.Location);
        });
      } catch (err) {
        console.error('LINE 56 Upload error:', err);
      }
    };
    if (selectedFile) {
      handleUpload(selectedFile);
    }
  }, [selectedFile]);

  return (
    <div>
      <div>File Upload</div>
      <input type="file" onChange={handleFileInput} />
      <button> Upload your icon</button>

      {imageUrl && (
        <div>
          <p>Image uploaded successfully!</p>
          <img
            className="w-9 h-9 rounded"
            src={imageUrl ? imageUrl : photo}
            alt="Profile"
          />
        </div>
      )}
    </div>
  );
};

export default UploadImageToS3WithReactS3;
