import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Having a base api allows us to maintain without the need to register slices.
// We can expand upon the api, creating new slices which have their own subset of end points
// This is where we'd be able to define a localhost instance if we want a localhost API running
export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://aact-api-2mwrpa6dza-ew.a.run.app/',
  }),
  endpoints: () => ({}),
});
