import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import chevronImage from '../../images/chevron_gray_icon.svg';
import clsx from 'clsx';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';
import {
  getAuth,
  createUserWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';

const SignUpCreatePassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const birthday = location.state?.birthday;

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pwd = e.target.value;
    setPassword(pwd);
    evaluatePasswordStrength(pwd);
  };

  const evaluatePasswordStrength = (pwd: string) => {
    let strength = 'Weak';
    const strongPasswordRegex = new RegExp(
      '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#%])[A-Za-z\\d!@#%]{8,20}$',
    );

    if (strongPasswordRegex.test(pwd)) {
      strength = 'Strong';
    } else if (pwd.length >= 8) {
      strength = 'Moderate';
    }

    setPasswordStrength(strength);
  };

  // ... inside your SignUpCreatePassword component

  const handleNextClick = async () => {
    if (passwordStrength !== 'Strong') {
      alert('Please enter a strong password before proceeding.');
      return;
    }
    if (!email) {
      alert('Email not provided.');
      return;
    }
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: userCredential.user.email,
        birthday: birthday,
      });

      await setPersistence(auth, browserSessionPersistence);

      // Get the 'from' query parameter
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get('from');

      // Redirect to the 'from' URL if it exists and starts with '/', otherwise go to '/graph'
      if (from && from.startsWith('/')) {
        navigate(from);
      } else {
        window.location.href = '/graph';
      }
    } catch (error: any) {
      console.error(error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          alert('Email address is already in use. Try signing in.');
          break;

        case 'auth/invalid-email':
          alert('Invalid email address.');
          break;

        case 'auth/network-request-failed':
          alert('Network error. Try again later.');
          break;

        case 'auth/weak-password':
          alert('Password is too weak.');
          break;

        default:
          alert('Internal error. Try again later.');
          console.error(error);
          break;
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-screen w-full bg-[#1F1F23] items-center justify-center">
      <div className="w-[580px] bg-[#2c2b32] rounded-lg shadow">
        <div className="px-12 py-12 flex flex-col gap-6">
          {/* Header */}
          <div className="flex items-center mb-4">
            <button onClick={handleBackClick} className="mr-4">
              <img
                src={chevronImage}
                className={clsx('w-4 h-4 trasnform rotate-90')}
              />
            </button>
            <h2 className="text-xl text-[#9491a7] font-medium">Sign up</h2>
          </div>

          {/* Title */}
          <h1 className="text-3xl text-white font-semibold mb-2">
            Create Password
          </h1>
          <p className="text-[#9491a7] mb-6">
            Please type something you’ll remember
          </p>

          {/* Password Input */}
          <div className="mb-6">
            <div className="flex items-center border-b border-[#9491a7] py-2">
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter your password"
                className="w-full bg-transparent text-white text-[25px] font-normal focus:outline-none"
              />
            </div>
          </div>

          {/* Password Requirements */}
          <div className="mb-6">
            <p className="text-white text-base mb-4">
              Your password must have at least:
            </p>
            <ul className="list-disc list-inside text-white text-sm space-y-2">
              <li>8 characters (20 Max)</li>
              <li>1 letter and 1 number</li>
              <li>1 special character (Example: #!@%)</li>
            </ul>
          </div>

          {/* Password Strength Indicator */}
          <div className="mb-6">
            <div className="w-full h-2 bg-[#9491a7] rounded-full mb-2 overflow-hidden">
              <div
                className={`h-full ${
                  passwordStrength === 'Strong'
                    ? 'bg-[#00c7a8] w-full'
                    : passwordStrength === 'Moderate'
                      ? 'bg-yellow-500 w-1/2'
                      : 'bg-red-500 w-1/4'
                }`}
              ></div>
            </div>
            <p className="text-white text-sm">
              Password Strength:{' '}
              <span className="font-bold">{passwordStrength || 'N/A'}</span>
            </p>
          </div>

          {/* Next Button */}
          <button
            onClick={handleNextClick}
            className="w-full bg-[#00c7a8] text-[#101117] gap-2 py-3 rounded-lg flex items-center justify-center hover:bg-[#00b89b] transition"
          >
            <span className="text-lg font-medium">Next</span>
            <AtomIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpCreatePassword;
