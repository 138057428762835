import { ScenarioColor } from './models/scenario_color';
import boxGreen from '../../images/box.svg';
import likeGreen from '../../images/like.svg';
import dislikeGreen from '../../images/dislike.svg';
import boxPurple from '../../images/box-purple.svg';
import likePurple from '../../images/like-purple.svg';
import dislikePurple from '../../images/dislike-purple.svg';
import boxOrange from '../../images/box-orange.svg';
import likeOrange from '../../images/like-orange.svg';
import dislikeOrange from '../../images/dislike-orange.svg';

export const ScenarioColors: ScenarioColor[] = [
  {
    mostLikely: '#00C7A8',
    mostLikelyIcon: boxGreen,
    bestCase: '#059B83',
    bestCaseIcon: likeGreen,
    worstCase: '#9AE8DB',
    worstCaseIcon: dislikeGreen,
  },
  {
    mostLikely: '#5D55AA',
    mostLikelyIcon: boxPurple,
    bestCase: '#3D338C',
    bestCaseIcon: likePurple,
    worstCase: '#AFA4E8',
    worstCaseIcon: dislikePurple,
  },
  {
    mostLikely: '#FFA600',
    mostLikelyIcon: boxOrange,
    bestCase: '#FF7A00',
    bestCaseIcon: likeOrange,
    worstCase: '#FFC67F',
    worstCaseIcon: dislikeOrange,
  },
];
