import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';
import chevronImage from '../../images/chevron_gray_icon.svg';
import clsx from 'clsx';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}

const SignUpPhoneOrEmailPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<'phone' | 'email'>('phone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState<'enterInfo' | 'enterCode'>('enterInfo');
  const [code, setCode] = useState(['', '', '', '', '', '']); // 6-digit code for Firebase
  const [confirmationResult, setConfirmationResult] = useState<any>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleTabClick = (tab: 'phone' | 'email') => {
    setSelectedTab(tab);
    setErrorMessage('');
  };

  const handleNextClick = () => {
    if (step === 'enterInfo') {
      if (selectedTab === 'phone') {
        if (!phoneNumber) {
          setErrorMessage('Please enter your phone number.');
          return;
        }
        setErrorMessage('');
        const auth = getAuth();
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-container',
            {
              size: 'invisible',
              callback: (response: any) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
              },
            },
          );
        }

        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((result) => {
            setConfirmationResult(result);
            setStep('enterCode');
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage(error.message);
          });
      } else {
        if (!email) {
          setErrorMessage('Please enter your email address.');
          return;
        }
        setErrorMessage('');
        navigate('/sign-up/create-password', {
          state: { email, birthday: location.state?.birthday },
        });
      }
    } else if (step === 'enterCode') {
      const codeStr = code.join('');
      if (codeStr.length < 6) {
        setErrorMessage('Please enter the 6-digit code.');
        return;
      }
      if (confirmationResult) {
        confirmationResult
          .confirm(codeStr)
          .then((result: any) => {
            navigate('/graph');
          })
          .catch((error: any) => {
            console.error(error);
            setErrorMessage('Incorrect code. Please try again.');
          });
      } else {
        setErrorMessage('No confirmation result available.');
      }
    }
  };

  const handleBackClick = () => {
    if (step === 'enterCode') {
      setStep('enterInfo');
      setErrorMessage('');
    } else {
      navigate(-1);
    }
  };

  const handleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (value && index < code.length - 1) {
        const nextInput = document.getElementById(
          `code-input-${index + 1}`,
        ) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleSendAgain = () => {
    // Resend the code
    handleNextClick();
  };

  return (
    <div className="flex h-screen w-full bg-[#1F1F23] items-center justify-center">
      <div className="w-[580px] bg-[#2c2b32] rounded-lg shadow">
        <div className="px-12 py-12 flex flex-col gap-6">
          {/* Header */}
          <div className="flex items-center mb-1">
            <button onClick={handleBackClick} className="mr-4">
              <img
                src={chevronImage}
                className={clsx('w-4 h-4 transform rotate-90')}
              />
            </button>
            <h2 className="text-xl text-[#9491a7] font-medium">Sign up</h2>
          </div>

          {step === 'enterInfo' && (
            <>
              {/* Tabs */}
              <div className="flex border-b border-[#676578] mb-6">
                <button
                  onClick={() => handleTabClick('phone')}
                  className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                    selectedTab === 'phone'
                      ? 'text-white border-b-2 border-white'
                      : 'text-[#9491a7]'
                  }`}
                >
                  Phone
                </button>
                <button
                  onClick={() => handleTabClick('email')}
                  className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                    selectedTab === 'email'
                      ? 'text-white border-b-2 border-white'
                      : 'text-[#9491a7]'
                  }`}
                >
                  Email
                </button>
              </div>

              {/* Input Fields */}
              {selectedTab === 'phone' && (
                <div className="mb-6">
                  <div className="flex items-center border-b border-[#9491a7] py-2">
                    <PhoneInput
                      onChange={(value) => setPhoneNumber(value || '')}
                      value={phoneNumber}
                      placeholder="Enter your phone number"
                      className="w-full"
                      defaultCountry="US"
                      numberInputProps={{
                        className:
                          'bg-transparent text-white border-none text-[25px] font-normal',
                      }}
                    />
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 mt-2 text-base">
                      {errorMessage}
                    </p>
                  )}
                </div>
              )}

              {selectedTab === 'email' && (
                <div className="mb-6">
                  <div className="border-b border-[#9491a7] py-2">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email address"
                      className="w-full bg-transparent text-white text-[25px] font-normal border-none"
                    />
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 mt-2 text-base">
                      {errorMessage}
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {step === 'enterCode' && (
            <>
              {/* Message */}
              <div className="mb-1">
                <div className="text-[#9491a7] text-base">
                  Enter the activation code we've sent via SMS to your phone{' '}
                  <span className="font-semibold">{phoneNumber}</span>
                </div>
                {errorMessage && (
                  <p className="text-red-500 mt-2 text-base">{errorMessage}</p>
                )}
              </div>
              {/* Code Input Fields */}
              <div className="flex justify-between items-center mb-1">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    id={`code-input-${index}`}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(e, index)}
                    className="w-[77px] h-[77px] rounded-[15px] border border-white bg-transparent text-white text-[32px] font-medium text-center"
                  />
                ))}
              </div>
              {/* Send Again */}
              <div className="flex justify-start items-center gap-2">
                <button
                  onClick={handleSendAgain}
                  className="text-[#00c7a8] text-base font-medium"
                >
                  Send Again
                </button>
              </div>
            </>
          )}

          {/* Next Button */}
          <button
            onClick={handleNextClick}
            className="w-full bg-[#00c7a8] text-[#101117] gap-2 py-3 rounded-lg flex items-center justify-center hover:bg-[#00b89b] transition"
          >
            <span className="text-lg font-medium">Next</span>
            <AtomIcon />
          </button>
        </div>
        {/* Recaptcha container */}
        <div id="recaptcha-container"></div>
      </div>
    </div>
  );
};

export default SignUpPhoneOrEmailPage;
