import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimatedSmall from './components/NeurouteAnimatedSmall';
import CarouselSelector from './components/CarouselSelector';

import { RootState } from '../../app/store';
import { setHelpHow, setStudyMethod } from '@/features/flow_1/flow_1_slice';

import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InvisibleInput,
  CornerPositioned,
  FlexRowContainer,
} from './components/StyledElements';
import { useAuth } from '../auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';
const listItems: string[] = ['Sleep', 'Eat', 'Do'];
const Step2Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow1);
  const { currentUser } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHelpHow(e.target.value));
  };

  const handleChangeCarousel = (value: string) => {
    dispatch(setStudyMethod(value));
  };

  const handleNextStep = () => {
    navigate('/flow-1-wireframes/step-3');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  return (
    <ProjectLayout title="Projects">
      <div className="absolute top-0 right-0 m-4">
        <a href="/profile">
          <AuthorAvatar author={currentUser} />
        </a>
      </div>
      <div className="flex flex-row">
        <CornerPositioned>
          <NeurouteAnimatedSmall />
        </CornerPositioned>

        {/* Left Side Panel */}
        <div className="w-60 max-w-60 p-4 block absolute top-10 left-0 overflow-hidden">
          <div className="mt-24">
            <CarouselSelector
              items={listItems}
              value={data.studyMethod}
              onChange={handleChangeCarousel}
            />
          </div>
        </div>

        <main className="flex-grow flex flex-col items-center p-4 pt-10">
          <div className="max-w-lg w-full text-left">
            <div className="text-gray-500 text-4xl mb-2 pl-6">I sleep</div>
            <div className="text-white text-4xl mb-8 pl-6">{data.disease}</div>
            <InputLikeElement className="mt-4">
              <InvisibleInput
                placeholder="How consistent is this?"
                value={data.helpHow}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <CircleButton onClick={handleNextStep}>
                <ArrowRightIcon />
              </CircleButton>
            </InputLikeElement>
          </div>
        </main>
      </div>
    </ProjectLayout>
  );
};

export default Step2Page;
