import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../lib/constants';

export interface SiteData {
  id: string;
  name: string;
}
export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getSiteById: builder.query<SiteData, string>({
      query: (id: string) => `site-by-id/${id}`,
    }),
  }),
});

export const { useGetSiteByIdQuery } = siteApi;
