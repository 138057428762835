import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { APIProvider } from '@vis.gl/react-google-maps';
import setupSentry from './core/setup_sentry';
import { AuthProvider } from './pages/auth/AuthContext';

import { router as DevRouter } from './app/router';
import { router as MasterRouter } from './app/hyper-launch-router';

setupSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
        <AuthProvider>
          <RouterProvider
            router={
              process.env.REACT_APP_HYPER_LAUNCH_MODE ? MasterRouter : DevRouter
            }
          />
        </AuthProvider>
      </APIProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
