import App from '../App';
import { createBrowserRouter } from 'react-router-dom';
import AuthGuard from '@/pages/auth/AuthGuard';
import ErrorPage from '@/pages/error/ErrorPage';
import ResearchList from '@/pages/dashboard/components/ResearchList';
import SignInPage from '@/pages/auth/SignInPage';
import SignUpPage from '@/pages/auth/SignUpPage';
import SignOutPage from '@/pages/auth/SignOutPage';
import ForgotPasswordPage from '@/pages/auth/ForgotPasswordPage';
import ProfilePage from '@/pages/auth/ProfilePage';
import MockMLPage from '@/pages/mock-ml/MockMLPage';
import ProjectsPage from '@/pages/create-project/ProjectsPage';
//
import DashboardPage from '@/pages/dashboard/DashboardPage';
import DashboardV2Page from '@/pages/dashboard/DashboardV2Page';
//
import CreateStudyPage from '@/pages/create-project/CreateProjectPage';
import CreateStudyFormPage from '@/pages/create-project/CreateStudyFormPage';
import CreateScenarioFormPage from '@/pages/create-project/CreateScenarioFormPage';
import AddInclusionCriteriaPage from '@/pages/create-project/AddInclusionCriteriaPage';
import AddExclusionCriteriaPage from '@/pages/create-project/AddExclusionCriteriaPage';

// New BuildProfile - flow 1 wireframes
import Flow1Step1Page from '@/pages/flow-1-wireframes/Step1Page';
import Flow1Step2Page from '@/pages/flow-1-wireframes/Step2Page';
import Flow1Step3Page from '@/pages/flow-1-wireframes/Step3Page';

// New BuildProfile - location input with Google Maps
import LocationPage from '@/pages/flow-1-wireframes/LocationPage';
// flow 3 wireframes
import Flow3Step2Page from '@/pages/flow-3-wireframes/Step2Page';
import Flow3Step3Page from '@/pages/flow-3-wireframes/Step3Page';
import Flow3Step4Page from '@/pages/flow-3-wireframes/Step4Page';
import Flow3Step5Page from '@/pages/flow-3-wireframes/Step5Page';
// flow 4
import Flow3Step6and7Page from '../pages/flow-3-wireframes/Step6and7Page';
// flow 2 - individual page
import IndividualPage from '@/pages/community-individual/IndividualPage';
// flow 5 - create
import InitiativesPage from '@/pages/initiatives/InitiativesPage';
import InitiativeCreateOrEditPage from '@/pages/initiatives/InitiativeCreateOrEditPage';
import InitiativeViewPage from '@/pages/initiatives/InitiativeViewPage';
// community home page with graph
import InitiativeGraphHomePage from '@/pages/community-home/InitiativeGraphHomePage';
import SubscriptionModal from '@/pages/initiatives/components/SubscriptionModal';
import UserManagementPage from '@/pages/user-management/UserManagementPage';
import CommentManagementPage from '@/pages/comment-management/CommentManagementPage';
import LoginOptionsPage from '@/pages/login-flow/LoginOptionsPage';
import SignUpBirthdayPage from '@/pages/login-flow/SignUpBirthdayPage';
import SignUpPhoneOrEmailPage from '@/pages/login-flow/SignUpPhoneOrEmailPage';
import SignUpCreatePassword from '@/pages/login-flow/SignUpCreatePasswordPage';
import SignInPhoneOrEmailPage from '@/pages/login-flow/SignInPhoneOrEmailPage';
import ForgotPasswordPageV2 from '@/pages/login-flow/ForgotPasswordPage';
import BuildProfileGraphPageStep0 from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep0';
import BuildProfileGraphPageStep1 from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep1';
import BuildProfileGraphStep2Page from '@/pages/poc-build-profile-1/BuildProfileGraphStep2Page';
import WaitingForCollision from '@/pages/poc-build-profile-1/WaitingForCollisionPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <InitiativeGraphHomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard/:id',
    element: (
      <AuthGuard>
        <DashboardV2Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard_old',
    element: (
      <AuthGuard>
        <App old />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/research_list',
    element: (
      <AuthGuard>
        <ResearchList />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginOptionsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login/phone-or-email',
    element: <SignInPhoneOrEmailPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login/forgot-password',
    element: <ForgotPasswordPageV2 />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-up/birthday',
    element: <SignUpBirthdayPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-up/phone-or-email',
    element: <SignUpPhoneOrEmailPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-up/create-password',
    element: <SignUpCreatePassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/sign-out',
    element: <SignOutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile',
    element: (
      <AuthGuard>
        <ProfilePage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/subscription',
    element: <SubscriptionModal />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/mock-ml-call',
    element: <MockMLPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/projects',
    element: (
      <AuthGuard>
        <ProjectsPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/wireframes/create-project',
    element: (
      <AuthGuard>
        <CreateStudyPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/wireframes/create-project-form',
    element: (
      <AuthGuard>
        <CreateStudyFormPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/wireframes/create-scenario-form',
    element: (
      <AuthGuard>
        <CreateScenarioFormPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/wireframes/add-inclusion-criteria-page',
    element: (
      <AuthGuard>
        <AddInclusionCriteriaPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/wireframes/add-exclusion-criteria-page',
    element: (
      <AuthGuard>
        <AddExclusionCriteriaPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  // flow 3 wireframes
  {
    path: '/flow-3-wireframes/step-2',
    element: (
      <AuthGuard>
        <Flow3Step2Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-1-wireframes/location',
    element: (
      <AuthGuard>
        <LocationPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-1-wireframes/step-1',
    element: (
      <AuthGuard>
        <Flow1Step1Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-1-wireframes/step-2',
    element: (
      <AuthGuard>
        <Flow1Step2Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-1-wireframes/step-3',
    element: (
      <AuthGuard>
        <Flow1Step3Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-3-wireframes/step-3',
    element: (
      <AuthGuard>
        <Flow3Step3Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-3-wireframes/step-4',
    element: (
      <AuthGuard>
        <Flow3Step4Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-3-wireframes/step-5',
    element: (
      <AuthGuard>
        <Flow3Step5Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/flow-3-wireframes/step-6-7/:projectId?',
    element: (
      <AuthGuard>
        <Flow3Step6and7Page />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  // community individual test page
  {
    path: '/community/individual',
    element: (
      <AuthGuard>
        <IndividualPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  // user initiative pages
  {
    path: '/initiatives',
    element: (
      <AuthGuard>
        <InitiativesPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/initiative/create',
    element: (
      <AuthGuard>
        <InitiativeCreateOrEditPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/initiative/edit/:id',
    element: (
      <AuthGuard>
        <InitiativeCreateOrEditPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/initiative/:id',
    element: (
      <AuthGuard>
        <InitiativeViewPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/initiatives/:id',
    element: (
      <AuthGuard>
        <InitiativeViewPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/graph',
    element: <InitiativeGraphHomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/user-management',
    element: <UserManagementPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/comment-management',
    element: <CommentManagementPage />,
    errorElement: <ErrorPage />,
  },
  //
  {
    path: '/poc-build-profile-1/step-0',
    element: <BuildProfileGraphPageStep0 />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/poc-build-profile-1/step-1',
    element: <BuildProfileGraphPageStep1 />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/waitlist',
    element: <BuildProfileGraphStep2Page />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/waiting-for-collision',
    element: <WaitingForCollision />,
    errorElement: <ErrorPage />,
  },
]);
