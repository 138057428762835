import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, writeBatch } from 'firebase/firestore';
import { db, auth } from '@/core/setup_firebase';
import { useAuth } from '../auth/AuthContext';
import { UserProfile } from '@/types/UserProfile';
import NeurouteLogo from '@/components/NeurouteLogo';
import HamburgerMenu from '@/components/HamburgerMenu';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';

type SortIconProps = {
  column: keyof UserProfile;
  currentColumn: keyof UserProfile;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

const UserManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [changedUsers, setChangedUsers] = useState<{
    [key: string]: UserProfile['role'];
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const [sortColumn, setSortColumn] = useState<keyof UserProfile>('email');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentUser?.role === 'super-admin') {
        const usersCollection = collection(db, 'users');
        const userSnapshot = await getDocs(usersCollection);
        const userList = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          role: doc.data().role || 'viewer',
        })) as UserProfile[];
        setUsers(userList);
      }
      setIsLoading(false);
    };

    fetchUsers();
  }, [currentUser]);

  const handleRoleChange = (userId: string, newRole: UserProfile['role']) => {
    setChangedUsers((prev) => ({ ...prev, [userId]: newRole }));
  };

  const saveChanges = async () => {
    const batch = writeBatch(db);

    Object.entries(changedUsers).forEach(([userId, newRole]) => {
      const userRef = doc(db, 'users', userId);
      batch.update(userRef, { role: newRole });
    });

    await batch.commit();

    setUsers(
      users.map((user) =>
        changedUsers[user.id] ? { ...user, role: changedUsers[user.id] } : user,
      ),
    );

    setChangedUsers({});
  };

  const handleSort = (column: keyof UserProfile) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    return 0;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (currentUser?.role !== 'super-admin') {
    return <div>You don't have access to this page.</div>;
  }

  return (
    <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
      <div className="flex flex-row p-6">
        <div className="w-3/12 flex-shrink-0">
          <div className="h-16 flex items-center justify-between mb-4">
            <NeurouteLogo />
            <img
              src={ArrowLeftCircleIcon}
              alt="Back"
              className="w-8 h-8 cursor-pointer hover:opacity-80 transition-opacity"
              onClick={() => navigate('/')}
            />
          </div>
        </div>

        <main className="w-9/12 flex-shrink-0 pl-8 pt-2 flex flex-col">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-4xl font-semibold tracking-tight">
              User Management
            </h1>
            <div className="flex items-center gap-4">
              {Object.keys(changedUsers).length > 0 && (
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  onClick={saveChanges}
                >
                  Save Changes
                </button>
              )}
              <div className="h-8 border-r border-zinc-700"></div>
              <HamburgerMenu />
            </div>
          </div>
          <div className="overflow-auto">
            {users.length > 0 ? (
              <table className="min-w-full bg-[#1F1F23] text-gray-300">
                <thead>
                  <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('email')}
                    >
                      Email
                      <SortIcon
                        column="email"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('role')}
                    >
                      Role
                      <SortIcon
                        column="role"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th className="p-2 font-normal">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user) => (
                    <tr
                      key={user.id}
                      className="border-b border-gray-700 text-start text-sm"
                    >
                      <td className="p-2">{user.email}</td>
                      <td className="p-2">
                        <select
                          value={changedUsers[user.id] || user.role}
                          onChange={(e) =>
                            handleRoleChange(
                              user.id,
                              e.target.value as UserProfile['role'],
                            )
                          }
                          className="bg-gray-800 text-gray-200 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="viewer">Viewer</option>
                          <option value="moderator">Moderator</option>
                          <option value="super-admin">Super Admin</option>
                        </select>
                      </td>
                      <td className="p-2">
                        {changedUsers[user.id] &&
                          changedUsers[user.id] !== user.role && (
                            <span className="text-yellow-400 text-sm">
                              Unsaved
                            </span>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center items-center h-64">
                <p className="text-2xl text-gray-400">No users found.</p>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserManagementPage;
