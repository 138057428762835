import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useSetFullScreen } from '../../../../features/enrollment_scenario/enrollment_scenario_slice';

type Props = {
  children: React.ReactNode;
};

export default function FullscreenGraph(props: Props) {
  const { children } = props;
  const fullScreen = useAppSelector((s) => s.enrollmentScenario.fullScreen);
  const height = fullScreen ? '100%' : 800;
  const dispatch = useAppDispatch();
  const setFullScreen = useSetFullScreen();

  return (
    <Fragment>
      <div id="fullscreen" className="flex relative w-full" style={{ height }}>
        {children}
      </div>

      <Transition show={fullScreen}>
        <Dialog
          className="relative z-10"
          onClose={(value) => {
            setFullScreen(value);
          }}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 p-8 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div
            className="fixed inset-0 z-10 w-screen h-full p-4"
            onClick={() => setFullScreen(false)}
          >
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute top-4 left-4 right-4 bottom-4">
                <div
                  className="flex relative h-full w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {children}
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}
